import React, { useRef, useEffect } from "react"

import styles from "./marqueeText.module.scss"

const MarqueeText = props => {
  // 1: left, -1: right
  const marqueeRef = useRef(null)
  const rate = props.rate ? props.rate : Math.random() * (2 - 0.5) + 0.5

  useEffect(() => {
    if (marqueeRef.current == null) return
    let frameId = null
    let offs = 0
    let direction = props.dir ? props.dir : 1
    function frame() {
      let h = marqueeRef.current
      if (h != null) {
        let width = h.children[0].getBoundingClientRect().width
        h.style.transform = `translateX(${
          direction === 1 ? -(offs % width) : -width - (offs % width)
        }px)`
      }
      offs += rate * direction
      window.requestAnimationFrame(frame)
    }
    frame()

    return () => {
      window.cancelAnimationFrame(frameId)
    }
  }, [marqueeRef, props.dir, rate])

  return (
    <h3 className={styles.marquee}>
      <span className={styles.marqueeWrapper} ref={marqueeRef}>
        <span className={styles.marqueeText}>{props.children}</span>
        <span className={styles.marqueeText} aria-hidden={true}>
          {props.children}
        </span>
        <span className={styles.marqueeText} aria-hidden={true}>
          {props.children}
        </span>
      </span>
    </h3>
  )
}

export default MarqueeText
